const weekSelectPlugin = require("flatpickr/dist/plugins/weekSelect/weekSelect");
$(document).on("turbolinks:load", function() {
    flatpickr(".schedules_date_picker", {
        dateFormat: 'Y-m-d',
        locale: {
            firstDayOfWeek: 1
        },
        altInput: false,
        enableTime: false,
        allowInput: false,
        clickOpens: true,
        closeOnSelect: true,
        plugins: [new weekSelectPlugin()],
        defaultDate: new Date($('input[name="start"]').val()),
        onChange: function (ev, picker) {
            var job_title =  $('#job_title option:selected').val();
            window.location = '/contacts/schedules?start=' + moment(picker).format('MM/DD/YYYY') + "&job_title=" + job_title;
        }
    });

    flatpickr(".schedule_requests_date_picker", {
        dateFormat: 'Y-m-d',
        locale: {
            firstDayOfWeek: 1
        },
        altInput: false,
        enableTime: false,
        allowInput: false,
        clickOpens: true,
        closeOnSelect: true,
        plugins: [new weekSelectPlugin()],
        defaultDate: new Date($('input[name="start"]').val()),
        onChange: function (ev, picker) {
            window.location = '/contacts/schedules/requests?start=' + moment(picker).format('MM/DD/YYYY');
        }
    });

    flatpickr(".labor_summary_date_picker", {
        dateFormat: 'Y-m-d',
        locale: {
            firstDayOfWeek: 1
        },
        altInput: false,
        enableTime: false,
        allowInput: false,
        clickOpens: true,
        closeOnSelect: true,
        plugins: [new weekSelectPlugin()],
        defaultDate: new Date($('input[name="start"]').val()),
        onChange: function (ev, picker) {
            window.location = '/reports/labor_summary?start=' + moment(picker).format('MM/DD/YYYY');
        }
    });

    flatpickr(".labor_summary_by_employee_date_picker", {
        dateFormat: 'Y-m-d',
        locale: {
            firstDayOfWeek: 1
        },
        altInput: false,
        enableTime: false,
        allowInput: false,
        clickOpens: true,
        closeOnSelect: true,
        plugins: [new weekSelectPlugin()],
        defaultDate: new Date($('input[name="start"]').val()),
        onChange: function (ev, picker) {
            window.location = '/reports/labor_summary_by_employee?start=' + moment(picker).format('MM/DD/YYYY') + '&user_id=' + $('input[name="user_id"]').val() + '&job_id=' + $('input[name="job_id"]').val();
        }
    });
});