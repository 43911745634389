$(document).on("turbolinks:load", function () {
    $('#inventory_item_require_sale_info').change(function () {
        $(".sales_info").toggleClass("d-none");
    });

    $('#inventory_item_require_purchase_info').change(function () {
        $(".purchase_info").toggleClass("d-none");
    });

    $('#inventory_item_track_inventory').change(function () {
        $(".track_inventory").toggleClass("d-none");
    });

    $(':radio[name="purchase_order[deliver_to]"]').change(function () {
        if (this.value === "customer") {
            $('#customer_deliver_to').removeClass('d-none')
        } else {
            $('#customer_deliver_to').addClass('d-none')
        }
    });

    $('#purchase_order_items').change(function () {
        this.childNodes.forEach(function (node, index) {
            const inputFields = $(node).find('input[type="number"]');
            if (inputFields.length) {
                const quantity = inputFields[0].value;
                const rate = inputFields[1].value;

                if (quantity && rate) {
                    $(node).find('.purchase_order_purchase_order_items_total input').val(parseFloat(rate) * parseFloat(quantity))
                }
            }
        })
    }).change();

});

