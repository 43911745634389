import Swal from 'sweetalert2'
$(document).on("turbolinks:load", function () {
    $("#employee_first_name, #employee_last_name").on("keyup", function () {
        var employee_name = $("#employee_first_name").val() + " " + $("#employee_last_name").val();
        if (employee_name != " ")
            $('.employee_business').text("What does " + employee_name + " do at your business?")
        else
            $('.employee_business').text("What does member do at your business?")
    });

    $(document).on('click', '.email-link', function (e) {
        var url = $(this).data("emailUrl");
        Swal.fire({
          title: 'Are you sure?',
          text: 'This will resend welcome email to user',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, Proceed!',
          cancelButtonText: 'Cancel'
        }).then((result) => {
          if (result.value) {
            $.ajax({
              url: url,
              type: "GET",
              success: function (data) {
                Swal.fire(data.title, data.message, "success");
              }
            });
          }
        })
      });
});



