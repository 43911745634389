$(document).on("turbolinks:load", function () {
    $(document).on('click', '.add-to-cart, .increment_quantity', function (e) {
        e.preventDefault();
        var checkout = $(this).hasClass('checkout');
        var modifier_ids = [];
        $(".modifiers-checkbox").each(function () {
            if (this.checked) {
                modifier_ids.push($(this).data('modifier-id'));
            }
        });
        var menu_item_id = $(this).data('menu-item-id')
        var uuid = $(this).data('uuid')
        var plus_btn_selector = $(this);
        if ($(this).hasClass("add-to-cart")) {
            plus_btn_selector = $('[href="' + "/load_modifier_sets/" + menu_item_id + '"]');
        }

        var count_textbox = plus_btn_selector.siblings('.count-textbox');
        var qty = parseInt(count_textbox.val()) + 1;

        $.ajax({
            url: "/add_to_cart/" + menu_item_id,
            type: "POST",
            data: { modifier_ids: modifier_ids, qty: qty, uuid: uuid },
            success: function (data) {
                // show decerement btn now
                plus_btn_selector.siblings().removeClass("d-none");
                count_textbox.val(qty);
                if (checkout) {
                    set_grand_total(data);
                }
                else {
                    handle_subtotal(data);
                    $('#my-modal').modal('hide');

                }
            }
        });
    });

    $(document).on('click', '.modifiers-checkbox', function (e) {
        var total_price = parseFloat($("#menu_item_price").val());
        $(".modifiers-checkbox").each(function () {
            if (this.checked) {
                total_price += parseFloat(($(this).data('modifier-price')));
            }
        });
        $('.add-to-cart').text("Add to order - $" + two_decimal_places(total_price));
    });

    $('.tip').on('click', function () {
        var tip = parseFloat($(this).data('tip'));
        var tip_percent = $(this).data('tip-percent');
        $("#tip").val(two_decimal_places(tip));
        $('.tip').removeClass('active');
        $(this).addClass('active');
        $('.tip_percent').text(tip_percent + "% ");
        $('.tip_amount').text(two_decimal_places(tip));
        var total = parseFloat($('#subtotal').val());
        var tip = parseFloat($("#tip").val());
        $('#grand_total').val(two_decimal_places(total + tip));
        $('.grand_total').text("$" + two_decimal_places(total + tip));
    })

    // $('.increment_quantity').on('click', function () {
    //     var element = $(this);
    //     var checkout = $(this).hasClass('checkout');
    //     var menu_item_id = $(this).data('menu-item-id')
    //     var count_textbox = $(this).siblings('.count-textbox')
    //     var qty = parseInt(count_textbox.val()) + 1;
    //     $.ajax({
    //         url: "/add_to_cart/" + menu_item_id,
    //         type: "POST",
    //         data: { modifier_ids: [], qty: qty },
    //         success: function (data) {
    //             // show decerement btn now
    //             element.siblings().removeClass("d-none");
    //             count_textbox.val(qty);
    //             if (checkout) {
    //                 set_grand_total(data);
    //             }
    //             else {

    //                 handle_subtotal(data);
    //             }
    //         }
    //     });
    // })

    $('.decrement_quantity').on('click', function () {
        var element = $(this);
        var checkout = $(this).hasClass('checkout');
        var menu_item_id = $(this).data('menu-item-id')
        var uuid = $(this).data('uuid')
        var count_textbox = $(this).siblings('.count-textbox')
        var qty = parseInt(count_textbox.val()) - 1;

        $.ajax({
            url: "/remove_from_cart/" + menu_item_id,
            type: "POST",
            data: { qty: qty, uuid: uuid },
            success: function (data) {
                count_textbox.val(qty);
                if (checkout) {
                    set_grand_total(data);
                    if (qty == 0) {
                        element.parents('.meal-content').remove();
                    }
                    else {

                    }
                }
                else {
                    handle_subtotal(data);
                }
            }
        });
    })


    function handle_subtotal(data) {
        price = two_decimal_places(data);
        if (data.subtotal > 0) {
            $(".your-order-content").removeClass("d-none");
            $(".subtotal").text("Subtotal: $" + data.subtotal);
            $(".subtotal-mobile").text("$" + data.subtotal);
        }
        else {
            $(".your-order-content").addClass("d-none");
        }
    }

    function set_grand_total(data) {
        var tip = parseFloat($("#tip").val());
        $("#item-total").text("$" + two_decimal_places(data.subtotal));
        $("#item-tax").text("$" + two_decimal_places(data.tax));
        $('#subtotal').val(data.subtotal + data.tax);
        $('#grand_total').val(two_decimal_places(data.subtotal + data.tax + tip));
        $('.grand_total').text("$" + two_decimal_places(data.subtotal + data.tax + tip));
    }

    function two_decimal_places(num) {
        return (Math.round(num * 100) / 100).toFixed(2);
    }

});


