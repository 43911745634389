import Sortable from 'sortablejs';

$(document).on("turbolinks:load", function () {

  var el = document.getElementsByClassName('sortable-discount-row');
  if (el.length) {
    var sortable = new Sortable(el[0], {
      handle: '.fa-arrows',
      onEnd: function (evt) {
        var position = evt.newIndex;
        var discount_id = evt.item.children[0].children[0].dataset["discountId"];
        var discount_page = evt.item.children[0].children[1].dataset["discountPage"];
        var url = "/loyalty/discounts/" + discount_id + "/set_position";
        $.ajax({
          url: url,
          type: "POST",
          data: {
            position: position,
            page: discount_page
          },
          success: function (data) {

          }
        });

      }
    });
  }

  var el = document.getElementsByClassName('sortable-floor-row');
  if (el.length) {
    var sortable = new Sortable(el[0], {
      handle: '.fa-arrows',
      onEnd: function (evt) {
        var position = evt.newIndex;
        var floor_id = evt.item.children[0].children[0].dataset["floorId"];
        var floor_page = evt.item.children[0].children[1].dataset["floorPage"];
        var url = "/product_layouts/floors/" + floor_id + "/set_position";
        $.ajax({
          url: url,
          type: "POST",
          data: {
            position: position,
            page: floor_page
          },
          success: function (data) {

          }
        });

      }
    });
  }

  var el = document.getElementsByClassName('sortable-menu-category-row');
  if (el.length) {
    var sortable = new Sortable(el[0], {
      handle: '.fa-arrows',
      onEnd: function (evt) {
        var position = evt.newIndex;
        var menu_category_id = evt.item.children[0].children[0].dataset["menuCategoryId"];
        var menu_category_page = evt.item.children[0].children[1].dataset["menuCategoryPage"];
        var url = "/products/menu_categories/" + menu_category_id + "/set_position";
        $.ajax({
          url: url,
          type: "POST",
          data: {
            position: position,
            page: menu_category_page
          },
          success: function (data) {

          }
        });

      }
    });
  }

 var el = document.getElementsByClassName('sortable-menu_item-row');
  if (el.length) {
    var sortable = new Sortable(el[0], {
      handle: '.fa-arrows',
      onEnd: function (evt) {
        var position = evt.newIndex;
        var item_id = evt.item.children[0].children[0].dataset["menuItemId"];
        var menu_items_page = evt.item.children[0].children[1].dataset["menuItemPage"];
        var url = "/products/menu_items/" + item_id + "/set_position";
        $.ajax({
          url: url,
          type: "POST",
          data: {
            position: position,
            page: menu_items_page,
          },
          success: function (data) {

          }
        });

      }
    });
  }

  var el = document.getElementsByClassName('sortable-sub-category-row');
  if (el.length) {
    var sortable = new Sortable(el[0], {
      handle: '.fa-arrows',
      onEnd: function (evt) {
        var position = evt.newIndex;
        var category_id = evt.item.children[0].children[0].dataset["subCategoryId"];
        var menu_category_page = evt.item.children[0].children[1].dataset["subCategoryPage"];
        var url = "/products/menu_sub_categories/" + category_id + "/set_position";
        $.ajax({
          url: url,
          type: "POST",
          data: {
            position: position,
            page: menu_category_page,
          },
          success: function (data) {

          }
        });

      }
    });
  }

  var el = document.getElementsByClassName('sortable-general-row');
  if (el.length) {
    var sortable = new Sortable(el[0], {
      handle: '.fa-arrows',
      onEnd: function (evt) {
        var position = evt.newIndex;
        var row_page = evt.item.children[0].children[1].dataset["rowPage"];
        var url = evt.item.dataset["url"];
        $.ajax({
          url: url,
          type: "POST",
          data: {
            position: position,
            page: row_page,
          },
          success: function (data) {

          }
        });

      }
    });
  }
});
