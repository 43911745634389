import Swal from 'sweetalert2'

$(document).on("turbolinks:load", function () {
  $(".make_ajax_call").on("click", function () {
    var url = $(this).data("deviceUrl");
    $.ajax({
      url: url,
      type: "GET",
      success: function (data) {
        Swal.fire(data.title, data.message, "success");
      }
    });
  })

  $(document).on('click', '.clear_device', function (e) {
    var url = $(this).data("deviceUrl");
    Swal.fire({
      title: 'Are you sure?',
      text: 'This will delete all orders / order item and charges stored locally by the device!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Proceed!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.value) {
        $.ajax({
          url: url,
          type: "GET",
          success: function (data) {
            Swal.fire(data.title, data.message, "success");
          }
        });
      }
    })
  });
});
