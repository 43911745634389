var Highcharts = require("highcharts");

$(document).on("turbolinks:load", function () {
    // get_revenue("current_month")
    // $("#today").click(function () {
    //     get_revenue(0)
    // });

    // $("#yesterday").click(function () {
    //     get_revenue("yesterday")
    // });

    // $("#current_month").click(function () {
    //     get_revenue("current_month")
    // });

    // function get_revenue(time_period = "today") {
    //     $.ajax({
    //         url: "/dashboard/branch_revenues.json",
    //         type: "GET",
    //         data: { time_period },
    //         success: function (data) {
    //             create_high_chart(data)
    //         }
    //     });
    // }

    // function create_high_chart(data) {
    //     const branches = []
    //     var series = data.map((branch) => {
    //         branches.push(branch.name)
    //         return {
    //             name: branch.name,
    //             y: parseFloat((branch.total_revenue / 100).toFixed(2))
    //         }
    //     })
    //     if ($("#container").length) {
    //         Highcharts.chart('container', {
    //             chart: {
    //                 type: 'column'
    //             },
    //             title: {
    //                 text: ''
    //             },
    //             accessibility: {
    //                 announceNewData: {
    //                     enabled: true
    //                 }
    //             },
    //             xAxis: {
    //                 title: {
    //                     text: 'Branch'
    //                 },
    //                 categories: branches,
    //                 crosshair: true
    //             },
    //             yAxis: {
    //                 title: {
    //                     text: 'Revenue $'
    //                 },
    //                 labels: {
    //                     format: "$ {value}"
    //                 }
    //             },
    //             legend: {
    //                 enabled: false
    //             },
    //             plotOptions: {
    //                 series: {
    //                     borderWidth: 0,
    //                     dataLabels: {
    //                         enabled: true,
    //                         format: '$ {point.y}'
    //                     }
    //                 }
    //             },

    //             tooltip: {
    //                 headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
    //                 pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>${point.y:.2f}</b><br/>'
    //             },

    //             series: [{
    //                 name: "Branch",
    //                 data: series
    //             }],
    //         });

    //     }

    // }
});
