
function search_items() {
  var query = $('#serach_value').val();
  $.ajax({
    url: '/products/menu_items/menu_items_search',
    data: { search: query, category: $('.category_id').val() },
    type: 'GET',
    success: function (data) {},
  });
}

function add_items(id, position, dir,name, price, description) {
  var url = $('.save-item').data('url');
  $.ajax({
    method: 'POST',
    data: {
      menu_item_id: id,
      position: position,
      direction: dir,
      name: name,
      price: price,
      description: description
    },
    url: url,
  });
  bind_delete();
}

function bind_delete(){
  $("#delete_mbi span").off().on('click', function (e)  {
    e.preventDefault();
    e.stopPropagation();

    var menu_item_id = e.currentTarget.dataset["menuItemId"];
    var url = $('.delete-item').data('url');
    $.ajax({
      method: 'DELETE',
      data: {
        menu_item_id: menu_item_id
      },
      url: url,
    });
    e.currentTarget.parentElement.parentElement.innerHTML = ""    
  })
}
var parent_box
$(document).on('turbolinks:load', function () {
  bind_delete();
  $('.item-box').on('click', function (e) {
    parent_box = $(this)
    var dir = $(this).data('dir');
    var position = $(this).data('position');
    var child_row = $(this).children().first();
    var menu_item_id = child_row.attr('data-menu-item-id');
    var price = child_row.attr('data-price');
    var description = child_row.attr('data-description');
    var name  = child_row.attr('data-name');
    $('#dir').val(dir);
    $('#position').val(position);
    $("#menu_item").val(menu_item_id);
    $("#menu_item").select2().trigger('change');
    $("#price").val(price)
    $("#name").val(name)
    $("#description").val(description)
    $('#item_search').modal('show');
  });

  $('.headers_change').on('keyup', function (e) {
    var url = $('.save-headings').data('url');
    var headings =  {
      header_1: $(".header_1").val(),
      header_2: $(".header_2").val(),
      title_1: $(".title_1").val(),
      title_2: $(".title_2").val(),
      desc_1: $(".desc_1").val(),
      desc_2: $(".desc_2").val()
    }
    $.ajax({
      method: 'POST',
      data: {
        headings: headings
      },
      url: url,
    });
  });

  $('.menu_item_select').on('change', function (e) {

    var price = $(this).find(':selected').attr('data-price');
    var description = $(this).find(':selected').attr('data-description');
    var name  = $(this).find(':selected').attr('data-name');
    $("#price").val(price)
    $("#name").val(name)
    $("#description").val(description)
  });

  $('.save_menu_board_item').on('click', function (e) {
    var dir = $('#dir').val();
    var position = $('#position').val();
    var price = $('#price').val();
    var name = $('#name').val();
    var description = $('#description').val();

    var id = $('#menu_item').find(':selected').val();
    var description = $.trim(description).substring(0, 95)


    var insert_html = $('#menu_item').find(':selected').data('insert-html')
    // not using generate html anymore as only selecting item now

    // generate html with new values for insert_html
    // insert_html = '<row data-name="' + name +
    //   '" data-price=' + price +
    //   ' data-menu-item-id=' + id +
    //   ' data-description="' + description +
    //   '"><col-sm-12><col-sm-9><h3 class="menu-title">' + name +
    //   '</h3 > </col-sm-9> <col-sm-3> <h6 class="menutitle">' + "$" + price +
    //   '</h6 > </col-sm-3> </col-sm-12><col-sm-12> <p>' + description +
    //   '</p > </col-sm-12 > </row >'

    parent_box.html(insert_html);

    add_items(id, position, dir, name, price, description);
    //$(parent).html(insert_html);
    $('#item_search').modal('hide');
  });

  $('#serach_value').on('keydown', function (e) {
    search_items();
  });
  $('.category_id').on('change', function (e) {
    search_items();
  });
  /* draggable element */
  $('.droppable').on('dragover', function (e) {
    e.preventDefault();
  });
  $('.drggable').on('dragstart', function (e) {
    e.originalEvent.dataTransfer.setData('Text', e.target.id);
  });
  $('.droppable').on('drop', function (e) {
    e.preventDefault();
    var id = e.originalEvent.dataTransfer.getData('Text');
    var parent = $(e.target).closest('.item-box');
    $(parent).html($('#' + id).data('insert-html'));
    var dir = $(parent).data('dir');
    var position = $(parent).data('position');
    console.log(dir, position);

    var child_row = parent.children().first();
    //var menu_item_id = child_row.attr('data-menu-item-id');
    var price = child_row.attr('data-price');
    var name = child_row.attr('data-name');
    var description  = child_row.attr('data-description');

    add_items(id, position, dir,name, price, description);
    // add this line
  });

  const input = document.querySelector('#menu_board_page_video');
  console.log(input);

  // Bind to normal file selection
  input &&
    input.addEventListener('change', (event) => {
      // console.log('css', input.files[0].name);
      // Array.from(input.files).forEach((file) => uploadFile(file));
      // uploadFile(input.files[0]);
      // you might clear the selected files from the input
      $('.progress').removeClass('d-none');
      console.log('hello');
      const uploader = new Uploader(
        input.files[0],
        '/rails/active_storage/direct_uploads',
        this
      );

      uploader.upload().then(
        (blob) => {
          $.ajax({
            url: $('.save-video').data('url'),
            data: { file: blob.signed_id },
          }).done(function (data) {
            if (data.file_type == "video")
            {
              $('.upload-form').html(
                ' <video autoplay="" id="myVideo" loop="" muted=""><source src="' +
                  data.url +
                  '" type="video/mp4"></video>'
              );
            }
            else if (data.file_type == "image")
            {
              $('.upload-form').html(
                ' <img class="w-100" src="' +
                  data.url +
                  '" ></img>'
              );
            }
            else
            {
              $('.upload-form').html(
                ' <a target="_blank",  href="' +
                  data.url +
                  '" >View File</img>'
              );
            }
          });
        },
        (error) => {
          // report the error
          console.log(error);
        }
      );
    });
});
import { DirectUpload } from '@rails/activestorage';

export default class Uploader {
  constructor(file, url, vm) {
    this.file = file;
    this.url = url;
    this.vm = vm;
    this.directUpload = new DirectUpload(this.file, this.url, this);
  }

  upload() {
    return new Promise((resolve, reject) => {
      this.directUpload.create((error, blob) => {
        if (error) {
          // Handle the error
          reject(error);
        } else {
          // Add an appropriately-named hidden input to the form
          // with a value of blob.signed_id
          resolve(blob);
        }
      });
    });
  }

  directUploadWillStoreFileWithXHR(xhr) {
    xhr.upload.addEventListener('progress', (event) =>
      this.directUploadDidProgress(event)
    );
  }

  directUploadDidProgress(event) {
    // Use event.loaded and event.total to update the progress bar
    let percent = ((event.loaded / event.total) * 100).toFixed(1);
    $('.template-video-progress').css('width', percent + '%');
    $('.template-video-progress').html(percent + '%');
    console.log(percent);
  }
}
