// properties
function bootstrap_select_search() {
  $(".select_with_search").selectpicker({
    liveSearch: true,
    size: 10,
    actionsBox: true
  });
  $(".bootstrap_select").selectpicker({
    size: 10
  });
}

$(document).on("turbolinks:load", function() {
  $("#loyalty_setting_points").on("cocoon:after-insert", function(
    e,
    added_row
  ) {
    bootstrap_select_search();
  });
  $("#loyalty_reward_items").on("cocoon:after-insert", function(e, added_row) {
    bootstrap_select_search();
    var val = $("#loyalty_reward_reward_type").val();

    let dropdown = added_row.find("select");
    let hidden = added_row.find("input");
    if (val == "discount_category") {
      hidden.val("MenuSubCategory");
    } else {
      hidden.val("MenuItem");
    }

    dropdown.empty();

    dropdown.append(
      '<option selected="true" disabled>Choose Item/Category</option>'
    );
    dropdown.prop("selectedIndex", 0);

    const url = "/loyalty/loyalty_rewards/item_list?type=" + val;

    // Populate dropdown with list of provinces
    $.getJSON(url, function(data) {
      console.log(data);
      $.each(data, function(key, entry) {
        // console.log(key, entry);
        dropdown.append(
          $("<option></option>")
            .attr("value", entry[0])
            .text(entry[1])
        );
      });
    });
  });
});
