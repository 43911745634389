// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

window.jQuery = $;
window.$ = $;
require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');
require('bootstrap/dist/js/bootstrap');
require('jquery-validation/dist/jquery.validate');
require('select2/dist/js/select2.js');
require('flatpickr/dist/flatpickr');
require('jquery-slimscroll/jquery.slimscroll');
require('bootstrap-select/dist/js/bootstrap-select');
require('chartkick');
require('chart.js');
require('packs/dashboard');
require('packs/modals');
require('packs/masks');
require('packs/layouts');
require('packs/table_layouts');
require('packs/modifiers');
require('packs/loyalty');
require('packs/clipboard');
require('packs/inventory');
require('packs/cart');
require('packs/chart');
require('packs/sweetalert');
require('packs/sortable');
require('packs/employee');
require('packs/menu_item');
require('packs/company');
require('packs/device');
require('packs/schedule');
require('packs/schedules');
require('packs/menuboards');
// for drag and sort
// import { Sortable } from "@shopify/draggable";
// import interact from "interactjs";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'controllers';
//import cocoon for nested attributes
import 'cocoon';

$(document).on('turbolinks:load', function () {
  //main icon menu
  initMainIconMenu();
  initOrderTabs();
  initSlimscroll();
  initLeftMenuCollapse();
  // remove start
  $('.role').on('click', function () {
    $(this).toggleClass('selected');
  });
  // remove end

  $('.select_location').on('change', function () {
    window.location = '/select_location?lid=' + this.value;
  });
  $('.bootstrap_select').selectpicker({
    size: 10,
  });
  bootstrap_select_search();

  // select2
  $('.select2').select2({
    theme: 'bootstrap4',
  });

  // select2 double rendering on back btn fix
  $(document).on('turbolinks:before-cache', function (e) {
    return $('.select2').each(function () {
      return $(this).select2('destroy');
    });
  });

  $('.select2-multiple').select2({
    multiple: true,
    theme: 'bootstrap4',
  });
  // datepicker
  flatpickr('.datepicker', {
    altInput: true,
    dateFormat: 'm/d/Y',
  });

  flatpickr('.datepicker2', {
    altInput: true,
    dateFormat: 'm/d/Y',
  });


  flatpickr('.datetime_picker', {
    altInput: true,
    enableTime: true,
    allowInput: false,
    dateFormat: 'm/d/Y H:i',
  });

  flatpickr('.time_picker', {
    enableTime: true,
    noCalendar: true,
    altInput: true,
    dateFormat: 'm/d/Y H:i',
  });

  // tooltips
  $.fn.tooltip && $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="tooltip-custom"]').tooltip({
    template:
      '<div class="tooltip tooltip-custom" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>',
  });

  $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
    // save the latest tab; use cookies if you like 'em better:
    localStorage.setItem('lastTab', $(this).attr('href'));
  });

  // go to the latest tab, if it exists:
  var lastTab = localStorage.getItem('lastTab');
  if (lastTab) {
    $('[href="' + lastTab + '"]').tab('show');
  }

  // validation plugin
  $.validator.setDefaults({
    errorElement: 'span',
    errorPlacement: function (error, element) {
      error.addClass('invalid-feedback');
      element.closest('.form-group').append(error);
    },
    highlight: function (element, errorClass, validClass) {
      $(element).addClass('is-invalid');
    },
    unhighlight: function (element, errorClass, validClass) {
      $(element).removeClass('is-invalid').addClass('is-valid');
    },
  });

  // settings button toggle
  $(':radio[name="company[fees_type]"]').change(function () {
    var selected_type = $(this).val();
    switchFees(selected_type, ['flat', 'percentage']);
  });
  $('.switch_flat_perct').change(function () {
    var selected_type = $(this).val();
    switchFees(selected_type, ['flat', 'percentage']);
  });

  $('.validate').validate();

  // keep last pill open
  $('a[data-toggle="pill"]').on('shown.bs.tab', function (e) {
    // save the latest tab; use cookies if you like 'em better:
    localStorage.setItem('lastTab', $(this).attr('href'));
  });

  var lastTab = localStorage.getItem('lastTab');
  if (lastTab) {
    $('[href="' + lastTab + '"]').tab('show');
  }
});

// in case of show hide based on value use this function
function switchFees(type, match) {
  if (type == match[0]) {
    $('.s1').removeClass('d-none');
    $('.s2').addClass('d-none');
  } else if (type == match[1]) {
    $('.s2').removeClass('d-none');
    $('.s1').addClass('d-none');
  }
}
function initMainIconMenu() {
  $('.main-icon-menu .nav-link').on('click', function (e) {
    e.preventDefault();
    $(this).addClass('active');
    $(this).siblings().removeClass('active');
    $('.main-menu-inner').addClass('active');
    var targ = $(this).attr('href');
    $(targ).addClass('active');
    $(targ).siblings().removeClass('active');
  });
}
function initOrderTabs() {
  $('.left-nav a').on('click', function (e) {
    e.preventDefault();
    $(this).addClass('active');
    $(this).siblings().removeClass('active');
    var targ = $(this).attr('href');
    $(targ).addClass('active');
    $(targ).siblings().removeClass('active');
  });
}
function initSlimscroll() {
  $('.slimscroll').slimscroll({
    height: 'auto',
    position: 'right',
    size: '7px',
    color: '#e0e5f1',
    opacity: 1,
    wheelStep: 5,
    touchScrollStep: 50,
  });
}

function initLeftMenuCollapse() {
  // Left menu collapse
  $('.button-menu-mobile').on('click', function (event) {
    event.preventDefault();
    $('body').toggleClass('enlarge-menu');
    initSlimscroll();
  });
}

function bootstrap_select_search() {
  $('.select_with_search').selectpicker({
    liveSearch: true,
    size: 10,
    actionsBox: true,
  });
}
