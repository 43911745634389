import { Sortable } from "@shopify/draggable";
$(document).on("turbolinks:load", function () {
  var sortable_layout = new Sortable(
    document.querySelectorAll(".layout-grid"),
    {
      draggable: ".layout-box",
    }
  );

  sortable_layout.on("sortable:stop", function (e) {
    var source = e.dragEvent.source;
    var draggable_id = $(source).data("id");
    var draggable_type = $(source).data("type");
    save_draagable_position(e.newIndex + 1, draggable_id, draggable_type);
  });

  function save_draagable_position(position, draggable_id, draggable_type) {
    var layout_id = $(".page-ids").data("layout");
    var layout_page_id = $(".page-ids").data("layout-page");
    var url =
      "/product_layouts/layouts/" +
      layout_id +
      "/layout_pages/" +
      layout_page_id +
      "/save_laout_page_draggable_position";
    $.ajax({
      method: "POST",
      data: {
        position: position,
        draggable_id: draggable_id,
        draggable_type: draggable_type,
        update: "true"
      },
      url: url,
    });
  }
});
