import Swal from 'sweetalert2'

$(document).on("turbolinks:load", function () {
  $(document).on('click', '.save_modifier_set_first', function (e) {
    Swal.fire('Oops...', 'Select modifier set and save first!', 'error')
  });

  $(document).on('click', '.duplicate_sub_category', function (e) {
    var url = $(this).data("url");
    Swal.fire({
      title: 'Are you sure?',
      text: 'This will dupliate category with all of its items!',
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Yes, Proceed!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.value) {
        window.location = url;
      }
    })
  });

  $(document).on('click', '.duplicate_item', function (e) {
    var url = $(this).data("url");
    Swal.fire({
      title: 'Are you sure?',
      text: 'This will duplicate item with its modifiers and printers!',
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Yes, Proceed!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.value) {
        window.location = url;
      }
    })
  });

  $(document).on('click', '.transaction_refund', function (e) {
    var url = $(this).data("url");
    Swal.fire({
      title: 'Are you sure?',
      text: 'This will refund to customer!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Proceed!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.value) {
        window.location = url;
      }
    })
  });

  // change all to this in future
  $(document).on('click', '.sweetalert_by_url', function (e) {
    var url = $(this).data("url");
    var text = $(this).data("message");
    Swal.fire({
      title: 'Are you sure?',
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Proceed!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.value) {
        window.location = url;
      }
    })
  });

  $(document).on('click', '.global_delete', function (e) {
    var url = $(this).data("url");
    var message = $(this).data("message");
    Swal.fire({
      title: 'Are you sure?',
      text: message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      cancelButtonColor: '#c1c1c1',
      confirmButtonText: 'Yes, Delete!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.value) {
        $.ajax({
          url: url,
          type: "DELETE"
        }).done(function () {
          location.reload();
        });
      }
    })
  });

  $(document).on('click', '.global_ajax_call_with_alert', function (e) {
    var url = $(this).data("url");
    var warning_message = $(this).data("warning-message");
    Swal.fire({
      title: 'Are you sure?',
      text: warning_message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      cancelButtonColor: '#c1c1c1',
      confirmButtonText: 'Yes, Proceed!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.value) {
        window.location = url
      }
    })
  });

  $(document).on('click', '.gloabal_form_submit', function (e) {
    var url = $(this).data("url");
    var warning_message = $(this).data("warning-message");
    Swal.fire({
      title: 'Are you sure?',
      text: warning_message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      cancelButtonColor: '#c1c1c1',
      confirmButtonText: 'Yes, Proceed!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.value) {
        $("#gloabal_form_submit").submit();
      }
    })
  });

  $(document).on('click', '.refund_charge', function (e) {
    var url = $(this).data("url");
    var warning_message = $(this).data("warning-message");
    Swal.fire({
      title: 'Are you sure?',
      text: warning_message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      cancelButtonColor: '#c1c1c1',
      confirmButtonText: 'Yes, Proceed!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.value) {
        $("#partial_refund_charge").submit();
      }
    })
  });

});