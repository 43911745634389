$(document).on('turbolinks:load', function () {
  const modal_holder_selector = '#modal-holder';
  const modal_selector = '.modal';

  $(document).on('click', 'a[data-modal]', function () {
    const location = $(this).attr('href');
    // Load modal dialog from server
    $.get(location, (data) => {
      $(modal_holder_selector).html(data).find(modal_selector).modal('show');
      flatpickr('.time_picker', {
        enableTime: true,
        noCalendar: true,
        altInput: true,
        dateFormat: 'm/d/Y H:i',
      });
      flatpickr('.only_time_picker', {
        enableTime: true,
        noCalendar: true,
        altInput: true,
        dateFormat: 'H:i K',
      });
      $('.validate').validate();
      $('#sortable').sortable({
        update: function (event, ui) {
          var pos = ui.item.index();
          var url = ui.item.data('sort-url');
          console.log(url, ui);
          $.ajax({
            type: 'POST',
            url: url,
            data: { position: pos },
            success: function (data, textStatus, jqXHR) {},
          });
        },
      });
    });
    $(document).on('hidden.bs.modal', '#mainModal', function (e) {
      $('.modal-backdrop').remove();
    });
    return false;
  });

 
  $(document).on('click', 'a[data-modal-flat]', function () {
    const location = $(this).attr('href');
    // Load modal dialog from server
    $.get(location, (data) => {
      $(modal_holder_selector).html(data).find(modal_selector).modal('show');
      
      $('#decrement').on('click',function(){
        var val = $('#order-count').val() ;
        if (val >1) {
          val=val-1;
          $('#order-count').val(val) ;
          
        }
      });

      $('#increment').on('click',function(){
        var val = parseInt($('#order-count').val()) ;
        val=val+1;
        $('#order-count').val(val) ;
      });
      

    });
    $(document).on('hidden.bs.modal', '#mainModal', function (e) {
      $('.modal-backdrop').remove();
    });
    return false;
  });

  $(document).on('ajax:success', 'form[data-modal]', function (event) {
    const [data, _status, xhr] = event.detail;
    const url = xhr.getResponseHeader('Location');
    if (url) {
      // Redirect to url
      window.location = url;
    } else {
      // Remove old modal backdrop
      $('.modal-backdrop').remove();
      // Update modal content
      const modal = $(data).find('body').html();
      $(modal_holder_selector).html(modal).find(modal_selector).modal();
    }
    $(document).on('hidden.bs.modal', '#mainModal', function (e) {
      $('.modal-backdrop').remove();
    });

    return false;
  });
});
